import React from "react"
import { graphql } from "gatsby"

const Error = () => {

  return (
    <div>404 Oje</div>
  )
}

export default Error
